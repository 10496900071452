import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const loadData = (params, search, config) => (dispatch, b, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const q = sdk.listings.query({
    minStock: 1,
    stockMode: 'match-undefined',
    include: ['author', 'images', 'author.profileImage'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  });

  return q.then(listings => {
    // Pick only the id and type properties from the response listings
    let listingRefs = listings.data.data.map(({ id, type }) => ({ id, type }));
    // only get the first 5 listings
    listings.data.data = listings.data.data.slice(0, 10);
    dispatch(addMarketplaceEntities(listings));
    // only get the first 5 refs
    listingRefs = listingRefs.slice(0, 10);

    dispatch(queryListingsSuccess(listingRefs));

    // dispatch(queryListingsSuccess(listings)); // Dispatch an action with the fetched listings
    dispatch(fetchPageAssets(pageAsset, true));
    return listings;
  });
};
