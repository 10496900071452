import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import { AspectRatioWrapper, AvatarSmall, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { PriceBreakDownModal } from './ListingCardPricebreakdown';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, id, slug, classes } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);

  const fee = price?.amount * 0.05;
  const priceIncludingFee = price?.amount + fee;

  // price.amount = priceIncludingFee;

  const priceIncFeeMoney = new Money(priceIncludingFee, price.currency);
  const feeFormatted = new Money(fee, price.currency);
  const { formattedPrice: formattedPriceWithFee, priceTitle: priceTitleFee } = priceData(
    priceIncFeeMoney,
    config.currency,
    intl
  );

  const { formattedPrice: formattedFee } = priceData(feeFormatted, config.currency, intl);

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  return (
    <>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>

          {isBookable ? (
            <div className={css.perUnit}>
              <FormattedMessage
                id="ListingCard.perUnit"
                values={{ unitType: publicData?.unitType }}
              />
            </div>
          ) : null}
        </div>
      </NamedLink>
      <div className={css.priceValueIncFee}>
        <PriceBreakDownModal
          formattedPriceWithFee={formattedPriceWithFee}
          price={formattedPrice}
          fee={formattedFee}
        />
      </div>
    </>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isFreeShipping = listing?.attributes?.publicData?.shippingPriceInSubunitsOneItem === 0;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          {isFreeShipping && (
            <div className={css.freeShippingLabel}>
              <img src="https://i.imgur.com/z2p5hYF.png" />
            </div>
          )}
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />

          <AvatarSmall user={author} className={css.avatar} />

          {/* <div className={css.autorImage}>
          <img src={profileImage} alt={authorName} />
        </div> */}
        </AspectRatioWrapper>
        <div className={css.info}>
          <PriceMaybe
            price={price}
            publicData={publicData}
            config={config}
            intl={intl}
            id={id}
            slug={slug}
            classes={classes}
          />
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            {/* {showAuthorInfo ? ( */}
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
            {/* ) : null} */}
          </div>
        </div>
      </NamedLink>
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
